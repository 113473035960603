import fxAPI from "../../../api/fx.api";
import {isArray} from "../../../utils/verifyType.utils";
import {RATE_DATA_ASK_BID_DP_SYMBOL_NULL, RATE_DATA_IS_EMPTY_OR_INVALID} from "../../../constants/errorMsg.constant";
import {ErrorObjForToastBar} from "../../../types/errorObjType.type";
import {formatFXObjectList} from "../../../formatters/fx/api/response";
import {
    updateDP,
    updateFXOrderFormFX,
    updateHideCreateRateAlertItems,
    updateRateAlertFX, updateUnitQuote
} from "../../../slice/fx";
import {processApiError} from "../../../utils/errorHandling.utils";
import {store} from "../../../store/store";
import {FXOrderFormType, FXRateAlertType, FXType} from "../../../types/fx.type";
import {updateFxConfigDpUqConfigWithRate} from "../../../slice/fxConfig";

export const fetchRate = async (fxOrderForm:FXOrderFormType) => {
    const fxList = store.getState().fx.forexes
    //error-handling, is that necessory? Need to feel in?

    if (!fxOrderForm.source.currency || !fxOrderForm.target.currency) return;

    const fx = fxList.find(
        (v) =>
            v.symbol ===
            `${fxOrderForm.source.currency}${fxOrderForm.target.currency}` ||
            v.symbol ===
            `${fxOrderForm.target.currency}${fxOrderForm.source.currency}`
    );
    if (!fx) return;
    try {
        const response = await fxAPI.getRate([fx.symbol]);
        if (
            !response.data.data ||
            response.data.data.length === 0 ||
            !isArray(response.data.data)
        ) throw {
            errorMsg: RATE_DATA_IS_EMPTY_OR_INVALID,
            responseHttpStatus: response.status,
            responseData: response.data
        } as ErrorObjForToastBar
        if (
            !response.data.data[0].ask
            ||!response.data.data[0].bid
            || !response.data.data[0].symbol
            // || !response.data.data[0].dp
        ) throw {
            errorMsg: RATE_DATA_ASK_BID_DP_SYMBOL_NULL,
            responseHttpStatus: response.status,
            responseData: response.data
        } as ErrorObjForToastBar
        const fxObject = formatFXObjectList(response.data.data)[0];

        //if this is fxObject, then dispatch this....
        store.dispatch(updateFXOrderFormFX(fxObject))
        store.dispatch(updateUnitQuote(response.data.data[0].uq)) //this might be lagacy code
        store.dispatch(updateDP(response.data.data[0].dp))  //this might be lagacy code
        store.dispatch(updateFxConfigDpUqConfigWithRate({symbol:response.data.data[0].symbol, ask:response.data.data[0].ask as number, dp:response.data.data[0].dp, uq:  response.data.data[0].uq}))

        //if this is xxxx, then dispatch another?
        //handleExchange(fxObject);
    } catch (error) {
        processApiError(error, undefined, "FX rate is currently unavailable. Please try again later.") //this is non-FATAL
    }
};

export const fetchRateForRateAlert = async (fxRateAlert:FXRateAlertType) => {
    const fxList = store.getState().fx.forexes
    if (!(fxRateAlert.source && fxRateAlert.source.currency) || !(fxRateAlert.target && fxRateAlert.target.currency)) return;
    const fx = fxList.find(
        (v) =>
            v.symbol ===
            `${fxRateAlert.source.currency}${fxRateAlert.target.currency}` ||
            v.symbol ===
            `${fxRateAlert.target.currency}${fxRateAlert.source.currency}`
    );
    if (!fx) return;
    try {
        // const response = await fxAPI.getRate([fx.symbol]);
        const response = await fxAPI.getRate([fx.symbol]);
        if (
            !response.data.data ||
            response.data.data.length === 0 ||
            !isArray(response.data.data)
        ) throw {
            errorMsg: RATE_DATA_IS_EMPTY_OR_INVALID,
            responseHttpStatus: response.status,
            responseData: response.data
        } as ErrorObjForToastBar
        if (
            !response.data.data[0].ask
            ||!response.data.data[0].bid
            || !response.data.data[0].symbol
            // || !response.data.data[0].dp
        ){
            throw {
                errorMsg: RATE_DATA_ASK_BID_DP_SYMBOL_NULL,
                responseHttpStatus: response.status,
                responseData: response.data
            } as ErrorObjForToastBar
        }
        const fxObject:FXType = formatFXObjectList(response.data.data)[0];
        store.dispatch(updateRateAlertFX(fxObject))
        store.dispatch(updateHideCreateRateAlertItems(false))
        store.dispatch(updateFxConfigDpUqConfigWithRate({symbol:response.data.data[0].symbol, ask:response.data.data[0].ask as number, dp:response.data.data[0].dp, uq:  response.data.data[0].uq}))
    } catch (error) {
        store.dispatch(updateHideCreateRateAlertItems(true))
        processApiError(error, undefined, "FX rate is currently unavailable. Please try again later.") //this is non-FATAL
    }
};
