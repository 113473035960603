import ReactDOM from "react-dom/client";
import {
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements,
} from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { SnackbarProvider } from "notistack";

import App from "./App";
import Snackbar from "./components/common/Snackbar";

import {
	DEFAULT_SNACKBAR_OPTIONS,
	MAX_SNACKBAR_STACKS,
} from "./constants/common.constant";
import LocalizationContextProvider from "./contexts/localization";
import { store } from "./store/store";
import reportWebVitals from "./reportWebVitals";

import "./styles/_variables.scss";
import "./index.scss";

const router = createBrowserRouter(
	createRoutesFromElements(<Route path="*" element={<App />} />),
	{ basename: process.env.REACT_APP_PROJECT_BASE_URL }
);

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<ReduxProvider store={store}>
		<LocalizationContextProvider>
			<SnackbarProvider
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				hideIconVariant
				autoHideDuration={DEFAULT_SNACKBAR_OPTIONS.autoHideDuration}
				maxSnack={MAX_SNACKBAR_STACKS}
				Components={{ general: Snackbar }}
			>
				<RouterProvider router={router} />
			</SnackbarProvider>
		</LocalizationContextProvider>
	</ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
